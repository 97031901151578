import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "../pages/page.module.less";

const Page = ({ data: { markdownRemark: { frontmatter, html } } }) => {
  return (
    <Fragment>
      <Layout key={frontmatter.slug}>
        <Seo
          title={frontmatter.title}
        />
        <PageHeader
          header={frontmatter.title}
          subHeader={frontmatter.subHeader}
        />
        <Container>
          <Row className={`justify-content-md-center ${pageStyles.pageParentRow} ${pageStyles.pageRow}`}>
            <Col lg={10} md={12}>
              <div className={pageStyles.markdownContent} dangerouslySetInnerHTML={{ __html: html }} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </Fragment>
  )
};
export default Page;

export const query = graphql`
  query PageQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        subHeader
        slug
      }
      html
    }
  }
`;
